import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import smoothscroll from 'smoothscroll-polyfill';
import ReCAPTCHA from "react-google-recaptcha";

import Layout from "../components/Layout";
import ContactFormContent from "../components/ContactFormContent";


class ContactForm extends React.Component {


  render() {
    return (
      <form className={`${this.props.formName}-form contact-form flex-column justc-start alitems-center ${this.props.isDisplayed ? 'displayed' : 'not-displayed'}`} 
            name={this.props.formName} 
            method="post" 
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            data-netlify-recaptcha="true"
            action="/contact-confirmation/">
        <input type="hidden" name="form-name" value={this.props.formName} />
        <input type="hidden" name="bot-field" />
        <ContactFormContent formName={this.props.formName} isDisplayed={this.props.isDisplayed} color="dark"/>
        <div className="full-width flex-row justc-center mgt-m">
          <ReCAPTCHA sitekey={process.env.GATSBY_RECAPTCHA_KEY}/>
        </div>
      </form>
    )
  }
}

class ContactTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = { contactType: "no-form-selected" };
  }

  scrollDownToForm() {
    if (window.innerHeight < 1100) {
      window.scrollTo({
        top: 200,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  setContactType(event, type) {
    this.setState({contactType: type});
    this.scrollDownToForm();
  }

  componentDidMount() {
    smoothscroll.polyfill();

    if (this.props.stateFromPreviousPage && this.props.stateFromPreviousPage.contactType) {
      this.setState({ contactType: this.props.stateFromPreviousPage.contactType });
    }
  }

  render() {
    
    return (
      <div className={`section gradient-section contact-section ${this.state.contactType} `}>
        <div className="content-wrapper small white-text">
          <h3 className="title-3 section-title center white-text mgb-s">Contact</h3>
          <div className="contact-subdiv contact-selection-cards">
            <h5 className="title-5 medium-weight s-mgt-m">Vous souhaitez…</h5>
            <div className="mgt-s flex-row justc-space-b alitems-stretch
                                  s-flex-column s-alitems-stretch">
              <button className="contact-card commercial-card" 
                   onClick={(e) => {this.setContactType(e, "commercial-selected")}}>
                <img src="/img/icons/commercial-icon.svg" alt="Suitcase icon"/>
                Contacter le service commercial
              </button>
              <button className="contact-card assistance-card" 
                   onClick={(e) => {this.setContactType(e, "assistance-selected")}}>
                <img src="/img/icons/assistance-icon.svg" alt="Truck icon"/>
                Contacter le support client Electre
              </button>
              <button className="contact-card editeurs-card" 
                   onClick={(e) => {this.setContactType(e, "editeurs-selected")}}>
                <img src="/img/icons/editeurs-icon.svg" alt="Books icon"/>
                Contacter le service référencement
              </button>
            </div>
          </div>
          <div className="contact-subdiv form-wrapper">
            <ContactForm formName="commercial" isDisplayed={this.state.contactType === "commercial-selected"}/>
            <ContactForm formName="assistance" isDisplayed={this.state.contactType === "assistance-selected"}/>
            <ContactForm formName="editeurs" isDisplayed={this.state.contactType === "editeurs-selected"}/>
          </div>
        </div>
      </div>
    )
  }
}

const ContactPage = ({data, location}) => {
  const { footerData, navbarData } = data;
  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <title>Nous contacter</title>
      </Helmet>
      <ContactTemplate stateFromPreviousPage={location.state}/>
    </Layout>
  )
}

export default ContactPage;


export const contactPageQuery = graphql`
  query ContactPage {
    ...LayoutFragment
  }
`